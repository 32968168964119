import logo from "../../logo.svg";
import React, {useEffect, useState, Suspense} from "react";
import { db } from '@/firebase';
import { collection, getDocs, getDocsFromCache } from 'firebase/firestore';
import {Box, Chip, Stack} from '@mui/material';
import {styled} from "@mui/material";
import {Avatar} from "@mui/material";
import {Typography} from "@mui/material";
import ContentListItem from "./AnimeListItem";
import CircularProgress from '@mui/material/CircularProgress';
import { useRecoilState } from "recoil";
import {tagStore} from "@/store/tagStore";
import {tagState} from "@/store/tagState";

const MyChip = styled(Chip)(({ theme }) => ({
  borderRadius: '10px'
}));

const MyBox = styled(Box)(({ theme }) => ({
  borderRadius: '20px',
  padding: '20px',
  backgroundColor: '#FFF',
}));

const contents = {
  1: {
    'name': '俺TSUEEE',
    'thumbnailPath': './1.png'
  }
}

const Thumbnail = styled(Avatar)(({ theme }) => ({
  borderRadius: '20px'
}));

const SumaryBox = styled(Box)(({ theme }) => ({
  marginLeft: '20px'
}));


const AnimeList: React.FC = (props) => {
  const [animes, setAnimes] = useState<any[]>([]);
  const [tags, setTags] = useRecoilState<{[key:string]: any}>(tagStore);
  const [tagsState, setTagsState] = useRecoilState<string[]>(tagState);

  useEffect(() => {
    const animesCollectionRef = collection(db, 'versions/1/animes');

    getDocs(animesCollectionRef).then((querySnapshot: any) => {
      var buffer: any[] = [];
      querySnapshot.docs.forEach((doc: any) => {
        buffer.push({...doc.data(), id: doc.id});
      });
      setAnimes(buffer);
    });

    const tagsCollectionRef = collection(db, 'versions/1/tags');

    getDocs(tagsCollectionRef).then((querySnapshot: any) => {
      var buffer: {[key: string]: any} = {};
      querySnapshot.docs.forEach((doc: any) => {
        buffer[doc.ref.path] = doc.data();
      });
      setTags(buffer);
    });


  }, []);

  const filteredAnimes = animes.filter((a: any) => {
      if(!tagsState) return true;
      if(tagsState.length === 0) return true;
      return tagsState.every((key) => a.tags.map((atag:any)=>atag.path).includes(key))
    });

  return (
    <div style={styles.container}>
      <Stack spacing={2}>
        {animes.length > 0 ?
          filteredAnimes.map((anime: any, index: number) => (<ContentListItem key={index} {...anime}/>))
         : <Box sx={{ display: 'flex' }}>
            <CircularProgress color="secondary"/>
          </Box>
        }
      </Stack>
    </div>
  );
}

export default AnimeList;

const styles = {
  container: {
    width: '100%',
  },
};