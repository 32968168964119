import logo from "../../logo.svg";
import React from "react";
import styles from './tags-section.module.css'
import Grid from '@mui/material/Grid2';
import { Box, Chip } from '@mui/material';
import {styled} from "@mui/material";
import {Avatar} from "@mui/material";
import {Typography} from "@mui/material";
import {useRecoilState} from "recoil";
import {tagStore} from "@/store/tagStore";
import {DocumentReference} from "firebase/firestore";
import FavoriteIcon from '@mui/icons-material/Favorite';
import IconButton from "@mui/material/IconButton";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
const tagName = [
  '俺TSUEEE',
  '前世の記憶',
  '生まれて転生',
  '事故死',
  '学生',
  '一人で転生',
  '魔法有り',
  'ハーレム'
]

const MyChip = styled(Chip)(({ theme }) => ({
  borderRadius: '10px'
}));

const MyBox = styled(Box)(({ theme }) => ({
  borderRadius: '20px',
  padding: '10px',
  backgroundColor: '#FFF',
  width: '100%',
}));

const contents = {
  1: {
    'name': '俺TSUEEE',
    'thumbnailPath': './1.png'
  }
}

const Thumbnail = styled(Avatar)(({ theme }) => ({
  borderRadius: '20px'
}));

const SumaryBox = styled(Box)(({ theme }) => ({
  marginLeft: '20px'
}));

interface AnimeListItemProps {
  id: string;
  name: {
    ja: string,
    en: string
  };
  thumbnail: string;
  tags: DocumentReference[];
  cours: string[];
}

const AnimeListItem: React.FC<AnimeListItemProps> = (props) => {
  const [tags, setTags] = useRecoilState<{[key:string]: any}>(tagStore);
  //"data:image/jpeg;base64,"+props.thumbnail
  const thumbnailPrefix = 'https://storage.googleapis.com/jp-contents-matome.appspot.com/thumbnail/'

  return (
    <MyBox display="flex">
      <Thumbnail src={thumbnailPrefix + props.id + '.jpg'} sx={{ width: 80, height: 80 }}/>
        <SumaryBox>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold',      overflow: 'hidden',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 1, }} >{props.name['ja']}</Typography>
          <Box display="flex" flexWrap="wrap" gap={0}>
            {
              props.cours.map((e, index) => (
                <Typography sx={{lineHeight: 1.2, fontSize: 8}}  key={index} variant="caption" color={"#aaa"}>{index!=0 ? '/'+e : e}</Typography>

              ))
            }
          </Box>
          <Box display="flex" flexWrap="wrap" gap={0}>
            {
              props.tags.map((tag, index) => (
                <Typography sx={{ lineHeight: 1.2, fontSize: 10}}  key={index} variant="caption" color='secondary'>{tags[tag.path].name.ja}　</Typography>

              ))
            }
          </Box>
          <Box display="flex" justifyContent="flex-start">
            <IconButton aria-label="like" sx={{ padding: '5px' }}>
              <FavoriteBorderIcon sx={{ fontSize: 14, marginRight: '3px'}}/>
              <Typography sx={{lineHeight: 1.2, fontSize: 10}} variant="caption" color={"#aaa"}>0</Typography>
            </IconButton>
          </Box>
        </SumaryBox>
    </MyBox>
  );
}

export default AnimeListItem;