import logo from "../../logo.svg";
import React from "react";
import styles from './tag.module.css'
import {Box, Chip, Stack, Typography} from "@mui/material";
import MatrixTagSection from "./MatrixTagSection";
import ContentsList from "../AnimeList";

const MatrixPage: React.FC = (props) => {

  return (
          <Stack spacing={2}>
            {/*<MatrixTagSection></MatrixTagSection>*/}
            {/*<AnimeList></AnimeList>*/}
          </Stack>
  );
}

export default MatrixPage;