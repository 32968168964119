import React from 'react';
import './App.css';
import {Stack, Box, AppBar, Toolbar, Button, useScrollTrigger } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles'
import theme from "./theme/theme";
import CssBaseline from '@mui/material/CssBaseline'
import BottomNavigationBar from "./Component/BottomNavigationBar";
import ListPage from "./Component/ListPage";
import MatrixPage from "./Component/MatrixPage";
import Chip from "@mui/material/Chip";
import UpperAppBar from "@/Component/UpperAppBar";
import { RecoilRoot } from 'recoil';

function App() {
  const [navValue, setNavValue] = React.useState(1);
  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={{height: '20px'}} />
        <div style={styles.container}>
          <img src={'./icon.png'} alt="centered" style={styles.image} />
        </div>
        <Box sx={{padding: '20px', width: '100%',display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',}} >
            {
              navValue === 1 ? <ListPage />: <MatrixPage/>
            }

        </Box>
        <UpperAppBar/>
        {/*<BottomNavigationBar handleChange={setNavValue}/>*/}
      </ThemeProvider>
    </RecoilRoot>
  );
}


export default App;


const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: '300px',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '300px',
  },
};