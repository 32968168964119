import {AppBar, Toolbar, Typography, useScrollTrigger} from "@mui/material";
import React from "react";
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import {useRecoilState} from "recoil";
import {searchModalState} from "@/store/serchModalState";
const UpperAppBar: React.FC = (props) => {
  const [open, setOpen] = useRecoilState<boolean>(searchModalState);
  return (
    <ElevationScroll>
      <AppBar  elevation={0} >
        <Toolbar>
          <Typography>
            いせかいぷらす
          </Typography>
          <IconButton aria-label="search" onClick={()=>setOpen(true)}>
            <SearchIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </ElevationScroll>
  );
}

export default UpperAppBar;


function ElevationScroll(props: any) {
  const { children } = props;

  // スクロール位置を監視
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
  });

  return React.cloneElement(children, {
    style: {
      backgroundColor: trigger ? '#00B6DF' : 'transparent', // 背景色を切り替え
      transition: 'background-color 0.5s ease', // 背景色の遷移
    },
  });
}