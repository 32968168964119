import logo from "../../logo.svg";
import React from "react";
import styles from './tag.module.css'
import {Box, Chip, Stack, Typography} from "@mui/material";
import AnimeList from "../AnimeList";
import SearchModal from "@/Component/SearchModal";

const ListPage: React.FC = (props) => {

  return (
      <Stack spacing={2} sx={{width: '100%', maxWidth: '800px'}}>
        <SearchModal/>
        <AnimeList></AnimeList>
      </Stack>
  );
}

export default ListPage;