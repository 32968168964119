import {createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#FFF',
      contrastText: '#193238',
    },
    secondary:{
      main: '#00B6DF',
      contrastText: '#ffffff',
    },
    background: {
      default: '#F6F8F9',
    },
    text: { primary: '#193238', secondary: '#DDD'},
  },
  typography: {
    fontFamily: "'Noto Sans JP', sans-serif"
  }
});

export default theme;